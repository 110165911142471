<template>
    <!--
        name:网络招聘会详情
        desc:网络招聘会的基本信息+参会企业
        createTime:2021-01-19
        updateTime:
        author:AB
    -->

    <div class="container">
        <!-- 顶部基本信息的盒子 ========================begin -->
        <div class="banner-box">
            <div class="text-wrapper">
                <div class="text-content margin-top-xl text-bold" style="font-size: 32px">{{fairObj.holdField}}</div>
                <div class="text-content">时间：{{fairObj.holdStartTime}}-{{fairObj.holdEndTime}} </div>
                <div class="text-content">咨询电话：{{fairObj.contactsPhone}}</div>
                <div class="text-content">主办单位：{{fairObj.organizer}}</div>
<!--                <div class="text-content">协办单位：各镇，街道人社所</div>-->
            </div>
            <div class="fair-info-box">
                <div>本场招聘会：</div>
                <div><img src="../../../assets/img/users/icon/11.png" alt=""><span>参会企业<span class="text-red">{{fairObj.companiesNum}}</span>家</span></div>
                <div><img src="../../../assets/img/users/icon/12.png" alt=""><span>参会职位<span class="text-red">{{fairObj.positionNum}}</span>个</span></div>
                <div><img src="../../../assets/img/users/icon/13.png" alt=""><span>参会求职者<span class="text-red">{{fairObj.workNum}}</span>人</span></div>
            </div>
            <!--            <div class="text-content">信州2020年“金秋招聘月”专场招聘会</div>-->
            <div class="banner-search-box">
                <ul class="search-title-box">
                    <!-- <li class="search-title" :class="searchType==='company'?'action':''" @click="switchSearchType('company')">企业</li> -->
                    <li class="search-title" :class="searchType==='job'?'action':''" @click="switchSearchType('job')">职位</li>
                </ul>
                <div class="search-box">
                    <input type="text" class="search-input" placeholder="请输入关键字" v-model="searchKey">
                    <input type="button" class="search-button" @click="search"  value="搜索">
                </div>
            </div>
        </div>
        <!-- 顶部基本信息的盒子 ========================end -->
        <!-- 参会企业的列表===================begin -->
        <div class="company-box">
            <div class="company-title">—————— 参会企业 ——————</div>
            <div class="company-list-box">
                <div class="company-section" v-for="(item,index) in companyList" :key="index">
                    <div class="title">
                        <img :src="item.companyLogo==''?'/img/company_logo.9938063a.png':item.companyLogo" alt="">
                        <span class="cursor-pointer text-xl text-bold" @click = 'openCompanyDetails(item)'>{{item.companyName}}</span>
                    </div>
                    <div class="info">
                        <div class="info-item-box left">
                            <span class="title-key">在招职位</span><span class="title-value">{{item.count}}个</span>
                        </div>
                        <div class="info-item-box right">
                            <span class="title-key">公司规模</span><span class="title-value">{{item.companyScale}}</span>
                        </div>
                    </div>
                    <div class="hot-job">
                        <div class="hot-job-title">热招职位</div>
                        <div class="hot-job-cell-box">
                            <div class="hot-job-cell" v-for="(job,jIndex) in item.workList" :key = "jIndex" @click="routeToJobDetail(job)">
                                <span>{{job.workName}}</span><span>{{job.wagesRange}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <div class="btn-box-item left" @click = 'openCompanyDetails(item)'>公司介绍</div>
                        <div class="btn-box-item right" @click="openCompanyJobList(item)">视频面试</div>
                    </div>
                </div>
            </div>

        </div>
        <!-- 参会企业的列表===================end -->

        <!--        <div class="banner-empty-box"></div>-->
    </div>
</template>

<script>
    import userRequest from "@/api/user";

    export default {
        created() {
            this.fairId = this.$route.query.fairId
           this.getList()
        },
        mounted() {

        },
        data() {
            return {
                fairId:'',
                fairObj:{
                    holdField:''
                },   // 招聘会的基本信息
                companyList:[], // 参展企业列表
                searchType:'job',  // company or job
                searchKey:null, // 搜索的关键字
                pageNo:1,  //
                pageSize:100  //
            }
        },
        methods: {
            /**
             *
             * @param type 搜索的模式，有company 和 job两种模式
             */
            search(){
                this.getList()
            },
            getList(){
                userRequest.getOnlineJobFairDetails({id:this.fairId,pageNo:this.pageNo,pageSize:this.pageSize,companyName:this.searchKey}).then(res=>{
                    this.fairObj = res.data.jobFairPojo
                    this.companyList = res.data.memberBaseInfoList
                })
            },
            switchSearchType(type){
                this.searchType = type
            },
            openCompanyDetails(item) {
                this.$router.push({
                    path: '/user/companyNews',
                    query: {
                        companyId: item.id
                    }
                })
            },
            openCompanyJobList(item) {
                console.log("直接进入职位")
                this.$router.push({
                    path: '/user/companyNews/cJobs',
                    query: {
                        companyId: item.id
                    }
                })
            },
            routeToJobDetail(item) {
                // this.$router.push({
                //     path: '/user/jobDetail',
                //     query: {
                //         workId: item.positionIdList
                //     }
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../assets/css/global.scss';

    .container {

    }

    .banner-box {
        width: 100%;
        height: 410px;
        margin: 0px 0px 30px 0;
        background: url("../../../assets/img/users/bg/onlineJobFairBG.jpg") center no-repeat;
        padding: 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text-wrapper {
            /*margin-top: 60px;*/
            display: flex;
            flex-direction: column;
            align-items: center;

            .text-content {
                font-size: 23px;
                margin-top: 10px;
                color: #089a97;
                /*font-weight: ;*/
            }
        }

        .fair-info-box{
            font-size: 23px;
            margin-top: 15px;
            color: #089a97;
            display: flex;
            flex-direction: row;
            margin-top: 0px;
            div{
                margin-right: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .banner-search-box{
            width: 802px;
            height: 125px;
            background-color: rgba(255,255,255,0.4);
            border-radius: 5px;
            margin: 15px auto 0px;
            display: flex;
            flex-direction: column;

            padding:10px 60px;
            box-sizing: border-box;
            .search-title-box{
                list-style: none;
                .search-title{
                    display: inline-block;
                    font-size: 17px;
                    color: #089a97;
                    padding: 10px 5px;
                    margin: 0px 20px;
                    cursor: pointer;
                }

                .action{
                    background: url("../../../assets/img/users/icon/14.png") no-repeat center bottom;
                }

            }

            .search-box{
                display: flex;
                .search-input{
                    /*border: none;*/
                    font-size: 18px;
                    color: #757575;
                    background-color: #fff;
                    border-radius: 8px 0px 0px 8px;
                    height: 52px;
                    width: 560px;
                    border: 0;
                    padding-left: 20px;

                    &:focus{
                        outline: none;
                    }

                    &::placeholder{
                        color: #e2e2e2;
                    }

                }

                .search-button{
                    font-size: 20px;
                    color: #fff;
                    width: 105px;
                    height: 52px;
                    padding-left: 25px;
                    background: url(../../../assets/img/users/icon/search_icon.png) no-repeat 15px center #00c4cd;
                    border-radius: 0px 8px 8px 0px;
                    border: 0;
                    /*outline: none;*/
                    cursor: pointer;


                }
            }
        }

    }

    /*.banner-empty-box{*/
    /*    width: 100%;*/
    /*    height: 410px;*/
    /*}*/

    .company-box{
        width: 1275px;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .company-title{
            font-size: 24px;
            font-weight: 600;
        }

        .company-list-box{
            min-height: 400px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            .company-section{
                width: 240px;
                height: 360px;
                margin:8px 6px;
                padding: 10px 20px;
                border:1px solid #EEEEEE;
                background-color: #FFFFFF;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                position: relative;
                transition:all 0.5s;
                &:hover{
                    transform:scale(1.03);
                    box-shadow: 3px 3px 4px rgba(26, 26, 26, 0.2);
                }



                .title{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    margin: 10px 0px;
                    padding: 10px 0px;
                    border-bottom: 1px solid #e2e2e2;
                    img{
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                }

                .info{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    border-bottom: 1px solid #EEEEEE;
                    padding: 10px 0px;
                    .left{
                        border-right: 1px solid #e2e2e2;
                    }

                    &-item-box{
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        height: calc(2em + 10px);
                        padding: 10px 0px;
                        .title-key{
                            font-size: 16px;
                            font-weight: 400;
                        }

                        .title-value{

                        }
                    }
                }

                .hot-job{
                    padding: 10px 0px;
                    .hot-job-title{
                        text-align: center;
                        font-size: 18px;
                        font-weight: 600;
                        color: #333333;
                        height: 30px;
                        line-height: 30px;
                    }

                    .hot-job-cell-box{
                        display: flex;
                        flex-direction: column;

                        .hot-job-cell{
                            margin-bottom: 10px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            cursor: pointer;
                        }
                    }
                }

                .btn-box{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    width: 100%;
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    &-item{
                        width: 40%;
                        height: 40px;
                        border-radius: 10px;
                        line-height: 40px;
                        text-align: center;
                        color: #FFFFFF;
                        font-size: 16px;
                        font-weight: 400;
                        cursor: pointer;
                    }

                    .left{
                        background-color: #4a4160;
                    }

                    .right{
                        background-color: #1f8ce4;
                    }

                }

            }
        }

    }


</style>